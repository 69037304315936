<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.basicData')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <!-- Types -->
            <v-col class="pb-0" cols="12" lg="12" md="3" sm="4">
              <v-select
                :items="category"
                :item-text="(item) => item.name"
                :item-value="(item) => item.tree_code"
                v-model="myObj.type_code"
                :label="$vuetify.lang.t('$vuetify.type')"
                outlined
                :rules="generalRule"
              />
            </v-col>

            <!-- Name -->
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="myObj.name"
                :label="$vuetify.lang.t('$vuetify.userName')"
                outlined
                :rules="generalRule"
              />
            </v-col>

            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="myObj.code"
                :label="$vuetify.lang.t('$vuetify.code')"
                outlined
                :rules="generalRule"
              />
            </v-col>

            <v-col class="pb-0" cols="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.note')"
                v-model="myObj.note"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="green darken-2" @click="validate">
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import { dataTypesList } from "@/api/system";

export default {
  props: { dialog: Boolean, myObj: Object },
  data() {
    return {
      valid: false,
      category: [],
      generalRule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    getData() {
      dataTypesList()
        .then((res) => {
          this.category = [...res.items];
        })
        .catch((err) => {
          console.log("Data Type Error", err);
        });
    },
    cancelForm() {
      this.$emit("update:dialog", false);
      this.$refs.form.resetValidation();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("handledata", this.myObj);
        this.$refs.form.resetValidation();
      }
    },
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.getData();
      }
    },
  },
};
</script>